body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


p {
    margin-block-start: 4px;
    margin-block-end: 2px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

a {
  text-decoration: none;
}

.top {
  position: relative;
  width: 100vw;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bottom {
  position: flex;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.topStats{
              justify-content: space-around;
              height: 40;
             display: flex;
             flex-direction: row;
             justify-content: center;
             align-items: center;
              width: 340px;
}

.shareIcon {
  height: 40px;
  width: 40px;
  margin: 4px;
}

.animateNewField {
  transition: cubic-bezier(0.93, 0.27, 0, 3);

  /* transition: linear; */
}

.divColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}